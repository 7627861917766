import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const FirstFoldContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.home-top {
    display: flex;
    position: relative;
    min-height: 800px;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      min-height: 80vh;
    }
  }
`;

export const SectionTitleContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="section" {...props} />
)`
  &.title-container {
    display: flex;
    flex-direction: column;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(8, 10)};
    padding-top: min(
      ${({ theme: { spacing } }: ThemeType) => spacing(30)},
      25vh
    );
    gap: ${({ theme: { spacing } }: ThemeType) => `min(${spacing(15)}, 10vh)`};
    z-index: 1;
    width: 100%;

    & > .tagline {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: ${({ theme: { spacing } }: ThemeType) => `min(${spacing(5)}, 8vh)`};
      & > .get-covered-button {
        width: 230px;
        height: 40px;
        text-transform: none;
        box-shadow: none;
        margin: 0 auto;
      }
      & > .text-container {
        color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};

        & > h1 {
          font-size: clamp(44px, 6vw, 72px);
          font-weight: bold;
          font-family: "utopia-std-display";
          line-height: 100%;
        }
        & > h2 {
          font-weight: 600;
          font-size: clamp(18px, 2vw, 24px);
        }
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(5, 2)};
      padding-top: ${({ theme: { spacing } }: ThemeType) => spacing(15)};
      & > .tagline {
        gap: ${({ theme: { spacing } }: ThemeType) =>
          `min(${spacing(5)}, 6vh)`};
        & > .get-covered-button {
          display: none;
        }

        & > .text-container {
          & > h1 {
            font-size: 48px;
          }
          & > h2 {
            font-size: 16px;
          }
        }
      }
    }
  }
`;
