import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { CoverListProps, SingleCoverItem } from "../types";
import { CoverListContainer } from "../styles";
import SingleCover from "./single-cover";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Link from "components/common/link";
import GetCoveredButton from "components/common/cover-button";
import ChevronDown from "assets/svg/chevron-down.svg";
import { PrismicRichText } from "@prismicio/react";

const CoverList: React.FC<CoverListProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicHomepage {
        nodes {
          data {
            body {
              ... on PrismicHomepageDataBodyHomepageSecondFoldAdditional {
                id
                primary {
                  additional_content_title {
                    richText
                  }
                }
                items {
                  content_paragraph {
                    richText
                  }
                  content_type
                  is_add_on
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data.body[3];
  return (
    <CoverListContainer className="cover-list-container">
      <GetCoveredButton
        location="what_we_cover_cta"
        text="Get covered"
        className="get-covered-button"
      />
      <Accordion className="cover-list-accordion-owner">
        <AccordionSummary
          expandIcon={<ChevronDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="cover-list-accordion-icon"
        >
          <PrismicRichText
            field={document.primary.additional_content_title.richText}
          />
        </AccordionSummary>
        <AccordionDetails className="cover-list-accordion">
          {document.items.map((item: SingleCoverItem, index: number) => {
            return (
              <SingleCover
                key={index}
                paragraph={item.content_paragraph.richText}
                isAddon={item.is_add_on}
                contentType={item.content_type}
              />
            );
          })}
          <Link className="cover-page-link" to="/cover">
            <span>See everything that's covered</span>
          </Link>
        </AccordionDetails>
      </Accordion>
    </CoverListContainer>
  );
};

export default CoverList;
