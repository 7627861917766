import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FourthFoldContainer } from "./styles";
import { FourthFoldProps } from "./types";
import FayeCard from "./components/faye-card";
import Box from "@mui/material/Box";
import { PrismicRichText } from "@prismicio/react";

const FourthFold: React.FC<FourthFoldProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicHomepage {
        nodes {
          data {
            body {
              ... on PrismicHomepageDataBodyHomepageThirdFold {
                id
                primary {
                  third_fold_bottom_right_text {
                    richText
                  }
                  third_fold_image_bottom_left {
                    gatsbyImageData
                  }
                  third_fold_image_top_right {
                    gatsbyImageData
                  }
                  third_fold_top_left_text {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data.body[4].primary;
  return (
    <FourthFoldContainer className="fourth-fold-container">
      <Box className="fourth-fold-max-width">
        <Box className="top-left">
          <PrismicRichText field={document.third_fold_top_left_text.richText} />
        </Box>
        <Box className="top-right">
          <GatsbyImage
            image={document.third_fold_image_top_right.gatsbyImageData}
            alt="view image"
          />
        </Box>
        <Box className="bottom-left">
          <GatsbyImage
            className="bottom-left-image"
            image={document.third_fold_image_bottom_left.gatsbyImageData}
            alt="smiling person"
          />
        </Box>
        <Box className="bottom-right">
          <FayeCard />
          <PrismicRichText
            field={document.third_fold_bottom_right_text.richText}
          />
        </Box>
      </Box>
    </FourthFoldContainer>
  );
};

export default FourthFold;
