import * as React from "react";
import { FayeCardContainer } from "./styles";
import { FayeCardProps } from "./types";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Box from "@mui/material/Box";
import { PrismicRichText } from "@prismicio/react";

const FayeCard: React.FC<FayeCardProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicHomepage {
        nodes {
          data {
            body {
              ... on PrismicHomepageDataBodyHomepageThirdFold {
                id
                primary {
                  faye_card_title {
                    richText
                  }
                  faye_card_paragraph {
                    richText
                  }
                  faye_card_image {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data.body[4].primary;
  return (
    <FayeCardContainer className="faye-card-container">
      <Box className="faye-card-title">
        <PrismicRichText field={document.faye_card_title.richText} />
        <PrismicRichText field={document.faye_card_paragraph.richText} />
      </Box>
      <Box className="faye-card-image">
        <GatsbyImage
          image={document.faye_card_image.gatsbyImageData}
          alt="faye card"
        />
      </Box>
    </FayeCardContainer>
  );
};

export default FayeCard;
