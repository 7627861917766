import React from "react";
import Seo from "components/common/seo";
import useMparticleLogEvent from "./hooks/useMparticleLogEvent";
import FirstFold from "./components/first-fold";
import SecondFold from "./components/second-fold";
import ThirdFold from "./components/third-fold";
import FourthFold from "./components/fourth-fold";
import { HomeContainer } from "./styles";

const Home: React.FC = () => {
  useMparticleLogEvent();

  return (
    <>
      <Seo />

      <HomeContainer className="home-container">
        <FirstFold />
        <SecondFold />
        <ThirdFold />
        <FourthFold />
      </HomeContainer>
    </>
  );
};

export default Home;
