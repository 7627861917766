import { useEffect } from "react";
import mParticle from "@mparticle/web-sdk";
import mixpanelKit from "@mparticle/web-mixpanel-kit";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import { nanoid } from "nanoid";
import { EventType } from "../types";
import { UTMProps } from "types/Utms";

const useMparticleLogEvent = () => {
  const location = useLocation();
  const isBrowser = typeof window !== "undefined";
  const getStoredCustomer = function () {
    if (isBrowser && window.localStorage.getItem("customer-id")) {
      return window.localStorage.getItem("customer-id");
    } else if (isBrowser) {
      let id = nanoid();
      window.localStorage.setItem("customer-id", id);
      return id;
    }
  };
  const mParticleLogin = function () {
    const UTMQuery = queryString.parseUrl(location.href);
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    }: UTMProps = UTMQuery.query;
    const props = Object.entries({
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    })
      .filter(([key, value]) => value)
      .map(([key, value]) => `${key}: ${value}`);

    const identityCallback = (result: any) => {
      if (result.getUser()) {
        if (props.length <= 0) return;
        result.getUser().setUserAttributeList("UTM properties", props);
        return;
      }
    };
    const mParticleConfig = {
      isDevelopmentMode: process.env.GATSBY_IS_DEVELOPMENT === "true",
      identifyRequest: {
        userIdentities: {
          customerid: getStoredCustomer(),
        },
      },
      identityCallback: identityCallback,
    };
    mixpanelKit.register(mParticleConfig);
    mParticle.init(process.env.GATSBY_MPARTICLE_API_KEY!, mParticleConfig);
    mParticle.logEvent("View home page", EventType.Other, {
      utm_source: utm_source ?? null,
      utm_medium: utm_medium ?? null,
      utm_campaign: utm_campaign ?? null,
      utm_term: utm_term ?? null,
      utm_content: utm_content ?? null,
    });
  };

  useEffect(() => {
    if (isBrowser) {
      mParticleLogin();
    }
  }, []);
};

export default useMparticleLogEvent;
