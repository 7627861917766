import * as React from "react";
import { SingleCoverProps, AddonTagProps } from "../../types";
import { SingleCoverContainer, AddonTagContainer } from "../../styles";
import { PrismicRichText } from "@prismicio/react";

const AddonTag: React.FC<AddonTagProps> = ({ contentType }) => {
  return (
    <AddonTagContainer
      className={`addon-tag-container ${contentType
        .toLowerCase()
        .replace(" ", "-")}`}
    >
      <p>{contentType}</p>
    </AddonTagContainer>
  );
};

const SingleCover: React.FC<SingleCoverProps> = ({
  paragraph,
  isAddon,
  contentType,
}) => {
  return (
    <SingleCoverContainer
      className={`single-cover-container ${isAddon && "is-addon"}`}
    >
      <PrismicRichText field={paragraph} />
      <AddonTag contentType={contentType} />
    </SingleCoverContainer>
  );
};

export default SingleCover;
