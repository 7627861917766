import * as React from "react";
import Box from "@mui/material/Box";
import { graphql, useStaticQuery } from "gatsby";
import { SectionTitleProps } from "../../types";
import { SectionTitleContainer } from "../../styles";
import { PrismicRichText } from "@prismicio/react";
import GetCoveredButton from "components/common/cover-button";

const SectionTitle: React.FC<SectionTitleProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      header_text_data: allPrismicHomepage {
        nodes {
          data {
            title {
              richText
            }
            secondary_title {
              richText
            }
          }
        }
      }
    }
  `);
  const document = data.header_text_data.nodes[0].data;

  return (
    <SectionTitleContainer className="title-container">
      <Box className="tagline">
        <Box className="text-container">
          <PrismicRichText field={document.title.richText} />
        </Box>
        <Box className="text-container">
          <PrismicRichText field={document.secondary_title.richText} />
        </Box>
        <GetCoveredButton
          className="get-covered-button"
          location="first_fold_cta"
          text="Get covered"
        />
      </Box>
    </SectionTitleContainer>
  );
};

export default SectionTitle;
