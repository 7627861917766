import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ThirdFoldContainer } from "./styles";
import { ThirdFoldProps, CoverCardItem } from "./types";
import Box from "@mui/material/Box";
import CoverCard from "./components/cover-card";
import CoverList from "./components/cover-list";
import ArrowLeft from "assets/svg/arrow-left.svg";
import ArrowRight from "assets/svg/arrow-right.svg";
import { PrismicRichText } from "@prismicio/react";
import { IconButton } from "@mui/material";

const ThirdFold: React.FC<ThirdFoldProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicHomepage {
        nodes {
          data {
            body {
              ... on PrismicHomepageDataBodyHomepageSecondFold {
                id
                primary {
                  second_fold_title {
                    richText
                  }
                  second_fold_secondary_title {
                    richText
                  }
                }
                items {
                  card_title {
                    richText
                  }
                  card_paragraph {
                    richText
                  }
                  card_image {
                    gatsbyImageData
                  }
                  is_covid_related
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data.body[2];
  const [cardIndex, setCardIndex] = useState<number | null>(null);
  const changeCardIndex = (direction: string) => {
    if (
      (direction === "forward" && cardIndex === 2) ||
      (direction === "backward" && cardIndex === 0)
    )
      return;
    setCardIndex((oldIndex) => {
      if (!oldIndex) return 1;
      let newIndex =
        direction === "forward" ? (oldIndex += 1) : (oldIndex -= 1);
      return newIndex;
    });
  };
  return (
    <ThirdFoldContainer className="third-fold-container">
      <Box className="third-fold-max-width">
        <Box className="third-fold-title-container">
          <PrismicRichText
            field={document.primary.second_fold_title.richText}
          />
          <PrismicRichText
            field={document.primary.second_fold_secondary_title.richText}
          />
        </Box>
        <Box className="transition-arrows">
          <IconButton
            className={`arrow ${cardIndex === 0 && "hidden"}`}
            onClick={() => changeCardIndex("backward")}
          >
            <ArrowLeft />
          </IconButton>
          <IconButton
            className={`arrow ${cardIndex === 2 && "hidden"}`}
            onClick={() => changeCardIndex("forward")}
          >
            <ArrowRight />
          </IconButton>
        </Box>
        <Box className="cover-cards-container snaps-inline">
          {document.items.map((item: CoverCardItem, index: number) => {
            return (
              <CoverCard
                key={index}
                index={index}
                cardIndex={cardIndex}
                image={item.card_image.gatsbyImageData}
                title={item.card_title.richText}
                paragraph={item.card_paragraph.richText}
                covid={item.is_covid_related}
                setCardIndex={setCardIndex}
              />
            );
          })}
        </Box>
        <CoverList />
      </Box>
    </ThirdFoldContainer>
  );
};

export default ThirdFold;
