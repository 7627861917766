import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const ThirdFoldContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.third-fold-container {
    min-height: 100vh;
    & > .third-fold-max-width {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(10)};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(8, 0)};
      & > .transition-arrows {
        display: none;
      }
      & > .cover-cards-container {
        display: grid;
        grid-auto-flow: column;
        max-width: 100vw;
        height: 460px;
        overflow-x: auto;
        overscroll-behavior-inline: contain;
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 10)};
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};

        &.snaps-inline {
          scroll-snap-type: inline mandatory;

          & > * {
            scroll-snap-align: center;
          }
        }
      }
      & > .third-fold-title-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
        & > h1 {
          font-style: normal;
          font-weight: normal;
          font-size: 50px;
          line-height: 96.24%;
          font-family: utopia-std-display;
        }
        & > h2 {
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 104.24%;
          color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
        }
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("lg")} {
        & > .cover-cards-container {
          padding: 0;
        }
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        & > .transition-arrows {
          display: flex;
          position: absolute;
          width: 100vw;
          justify-content: space-between;
          padding: 0 16px;
          transform: translateY(390px);
          z-index: 1;
          & > .hidden {
            visibility: hidden;
          }
          & > .arrow {
            cursor: pointer;
          }
        }
        & > .third-fold-title-container {
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 4)};
          & > h1 {
            font-size: 42px;
          }
          & > h2 {
            font-size: 18px;
          }
        }
      }
    }
  }
`;
