import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const FourthFoldContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.fourth-fold-container {
    & > .fourth-fold-max-width {
      width: 100%;
      max-width: 1440px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 auto;
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
      & > .bottom-left {
        & > .bottom-left-image {
          min-width: 100%;
        }
      }
      & > .top-left {
        background-color: ${({ theme: { palette } }: ThemeType) =>
          palette.primary.main};
        color: ${({ theme: { palette } }: ThemeType) =>
          palette.secondary.light};
        font-family: utopia-std-display;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        & > h2 {
          font-weight: normal;
          font-size: clamp(50px, 6vw, 80px);
          line-height: 106.24%;
        }
        & > h3 {
          font-weight: normal;
          font-size: clamp(25px, 3vw, 40px);
        }
      }
      & > .bottom-right {
        background-color: ${({ theme: { palette } }: ThemeType) =>
          palette.p_yellow.light};
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        position: relative;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};

        & > h3 {
          font-weight: 600;
          font-size: clamp(18px, 2.1vw, 30px);
          line-height: 120%;
        }
        & > h4 {
          font-weight: normal;
          font-size: clamp(12px, 1vw, 14px);
        }
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        padding: 0;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        & > .top-left {
          order: 1;
        }
        & > .top-right {
          order: 2;
        }
        & > .bottom-right {
          order: 3;
        }
        & > .bottom-left {
          order: 4;
        }
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
        & > .top-left {
          & > h2 {
            font-size: clamp(35px, 10vw, 42px);
          }
          & > h3 {
            font-size: clamp(18px, 5vw, 22px);
          }
        }
        & > .bottom-right {
          & > h3 {
            font-size: 16px;
          }
        }
      }
    }
  }
`;
