import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { SecondFoldProps } from "./types";
import { SecondFoldContainer } from "./styles";
import GetCoveredButton from "components/common/cover-button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PrismicRichText } from "@prismicio/react";

const SecondFold: React.FC<SecondFoldProps> = () => {
  const mobileView = useMediaQuery("(max-width: 719px)");
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicHomepage {
        nodes {
          data {
            body {
              ... on PrismicHomepageDataBodyHomepageFirstFold {
                id
                primary {
                  first_fold_title {
                    richText
                  }
                  first_fold_secondary_title {
                    richText
                  }
                  phone_app_back {
                    gatsbyImageData
                  }
                  phone_app_front {
                    gatsbyImageData
                  }
                  phone_app_front_mobile {
                    gatsbyImageData
                  }
                  phone_app_back_mobile {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      background_image: allPrismicHomepage {
        nodes {
          data {
            background_cover_image {
              gatsbyImageData(layout: FIXED)
            }
            background_cover_image_mobile {
              gatsbyImageData
            }
            background_cover_top_image {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data.body[0].primary;
  const background =
    data.background_image.nodes[0].data.background_cover_image.gatsbyImageData;
  const backgroundMobile =
    data.background_image.nodes[0].data.background_cover_image_mobile
      .gatsbyImageData;
  const womanImage =
    data.background_image.nodes[0].data.background_cover_top_image
      .gatsbyImageData;
  return (
    <SecondFoldContainer className="second-fold-container">
      <Box className="background-container">
        <GatsbyImage
          className="second-fold-background"
          image={mobileView ? backgroundMobile : background}
          alt=""
        />
      </Box>

      <Box className="second-fold-max-width">
        <Box className="title-and-app-links">
          <GatsbyImage
            className="second-fold-woman"
            image={womanImage}
            alt=""
          />
          <Box className="second-fold-title">
            <Box className="primary-title-container">
              <PrismicRichText field={document.first_fold_title.richText} />
            </Box>
            <PrismicRichText
              field={document.first_fold_secondary_title.richText}
            />

            <GetCoveredButton
              location="homepage_second_fold"
              className="get-covered-button"
              text="Get covered"
            />
          </Box>
        </Box>
        <Box className="phone-app-images">
          <GatsbyImage
            image={
              mobileView
                ? document.phone_app_front_mobile.gatsbyImageData
                : document.phone_app_front.gatsbyImageData
            }
            alt="phone app image"
            className="phone-app-front"
          />
          <GatsbyImage
            image={
              mobileView
                ? document.phone_app_back_mobile.gatsbyImageData
                : document.phone_app_back.gatsbyImageData
            }
            alt="phone app image"
            className="phone-app-back"
          />
        </Box>
      </Box>
    </SecondFoldContainer>
  );
};

export default SecondFold;
