import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/system";

export const HomeContainer = styled((props: BoxProps) => (
  <Box component="main" {...props} />
))`
  &.home-container {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.p_pink.background};
    ${({ theme }) => theme.breakpoints.down("sm")} {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
