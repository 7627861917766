import * as React from "react";
import { FirstFoldContainer } from "./styles";
import { FirstFoldProps } from "./types";
import SectionTitle from "./components/section-title";

const FirstFold: React.FC<FirstFoldProps> = () => {
  return (
    <FirstFoldContainer className="home-top">
      <SectionTitle />
    </FirstFoldContainer>
  );
};

export default FirstFold;
