import * as React from "react";
import { CovidTagContainer } from "../../styles";
import { CovidTagProps } from "../../types";

const CovidTag: React.FC<CovidTagProps> = () => {
  return (
    <CovidTagContainer className="covid-tag-container">
      <p>COVID-19</p>
      <p>included!</p>
    </CovidTagContainer>
  );
};

export default CovidTag;
