import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const SecondFoldContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.second-fold-container {
    min-height: max(100vh, 900px);

    & > .background-container {
      display: flex;
      justify-content: center;

      & > .second-fold-background {
        position: absolute;
        max-width: 100vw;
        transform: translateY(-25%);
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      min-height: max(120vh, 900px);

      & > .background-container {
        & > .second-fold-background {
          max-width: 100vw;
          transform: translateY(-20vh);
        }
      }
    }
    & > .second-fold-max-width {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      min-height: 100%;
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(8, 10)};
      & > .title-and-app-links {
        display: flex;
        flex-direction: column;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(8)};
        position: relative;
        text-align: center;
        flex-basis: 40%;
        & > .second-fold-woman {
          position: absolute;
          z-index: 2;
          transform: translate(-20px, -80%);
          width: min(310px, 20vw);
          min-width: 140px;
          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            transform: translate(-20px, 0);
          }
        }

        & > .second-fold-title {
          display: flex;
          flex-direction: column;
          width: max-content;
          gap: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
          z-index: 1;
          transform: translateY(25%);
          flex-basis: 50%;
          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            transform: translateY(120%);
          }
          & > .primary-title-container {
            & > h1 {
              font-family: utopia-std-display;
              font-weight: normal;
              font-size: clamp(44px, 4vw, 60px);
              line-height: 120%;
            }
          }
          & > h2 {
            font-weight: bold;
            font-size: 32px;
            font-size: clamp(16px, 1.8vw, 24px);
          }
          & > .get-covered-button {
            font-size: 14px;
            box-shadow: none;
            text-transform: none;
            padding: 0;
            margin: 0 auto;
            height: 40px;
            width: 230px;
            ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
              display: none;
            }
          }
        }
      }
      & > .phone-app-images {
        display: flex;
        flex-basis: 50%;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};

        & > * {
          & > * {
            & > img {
              max-width: 280px !important;
            }
          }
        }
        & > .phone-app-back {
          transform: translateY(55%);
        }
        & > .phone-app-front {
          transform: translateY(15%);
        }
        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          gap: 0;
          display: flex;
          align-items: center;
          flex-basis: 100%;
          justify-content: space-between;
          transform: translateY(60%);

          & > .phone-app-front {
            transform: translateX(0);
            & > * {
              max-width: none !important;
              & > img {
                width: 120%;
              }
            }
          }
          & > .phone-app-back {
            transform: translateX(0);

            & > * {
              max-width: none !important;
              & > img {
                width: 120%;
              }
            }
          }
        }
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        flex-direction: column;
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(5, 2)};
        min-height: calc(100vh - 100px);
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};

        & > .title-and-app-links {
          gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};

          & > .second-fold-title {
            gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
            & > .primary-title-container {
              & > h1 {
                font-size: clamp(42px);
              }
            }
            & > h2 {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
`;
