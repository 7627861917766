import React, { useEffect, useRef } from "react";
import { CoverCardProps } from "../types";
import { CoverCardContainer } from "../styles";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "components/common/link";
import CovidTag from "./covid-tag";
import Box from "@mui/material/Box";
import useIntersection from "../../hooks/useIntersection";
import { PrismicRichText } from "@prismicio/react";

const CoverCard: React.FC<CoverCardProps> = ({
  image,
  title,
  paragraph,
  covid,
  index,
  cardIndex,
  setCardIndex,
}) => {
  const cardRef = useRef<HTMLInputElement>(null);
  const inViewport = useIntersection(cardRef, "0px", 0.9);

  useEffect(() => {
    if (inViewport) {
      setCardIndex(index);
    }
    return () => {};
  }, [inViewport]);

  useEffect(() => {
    if (index === cardIndex && !inViewport) {
      cardRef?.current?.focus();
    }
    return () => {};
  }, [cardIndex]);

  return (
    <CoverCardContainer className="cover-card-container">
      <input inputMode="none" className="ref-input" ref={cardRef} />
      {covid && <CovidTag />}
      <Box className="image-owner">
        <GatsbyImage className="header-image" image={image} alt="card image" />
      </Box>
      <Box className="card-content-container">
        <PrismicRichText field={title} />
        <Box className="paragraph-container">
          <PrismicRichText field={paragraph} />
        </Box>
      </Box>
      <Box className="the-fine-print">
        <Link to="/cover">More details</Link>
      </Box>
    </CoverCardContainer>
  );
};

export default CoverCard;
