import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const FayeCardContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.faye-card-container {
    border-radius: 0px 14.4529px 14.4529px 14.4529px;
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.p_white.light};
    padding-top: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
    position: absolute;
    transform: translateY(-90%);
    & > .faye-card-title {
      display: flex;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
      & > h4 {
        font-weight: 800;
        font-size: clamp(16px, 1.5vw, 22px);
      }
      & > p {
        font-weight: normal;
        font-size: clamp(12px, 1.1vw, 16px);
        line-height: 0;
        color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
        & > strong {
          color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
        }
      }
    }
    width: max(24vw, 200px);
    @media screen and (min-width: 1440px) {
      width: 338px;
    }

    & > .faye-card-image {
      width: 90%;
      margin: 0 auto;
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      width: max(200px, 35vw);

      & > .faye-card-title {
        & > h4 {
          font-size: clamp(14px, 1.5vw, 22px);
        }
        & > p {
          font-size: clamp(11px, 1.2vw, 16px);
        }
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("sm")} {
      width: 230px;
      transform: translateY(-70%);
      & > .faye-card-title {
        & > h4 {
          font-size: clamp(12px, 4vw, 14px);
        }
        & > p {
          font-size: clamp(7px, 3vw, 11px);
        }
      }
    }
  }
`;
