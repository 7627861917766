import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const CoverCardContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.cover-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: 365px;
    overflow: auto;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 4)};
    border-radius: 4px;
    & > .ref-input {
      position: absolute;
      height: 0;
      width: 0;
      border: none;
      background-color: transparent;
      font-size: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      caret-color: transparent;
      &:focus {
        outline: none;
      }
    }
    & > .image-owner {
      flex-basis: 50%;
      & > .header-image {
      }
      display: flex;
      align-items: flex-end;
    }
    & > .the-fine-print {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 10%;
      width: 100%;
      border-top: ${({ theme: { palette } }: ThemeType) =>
        `1px solid ${palette.p_pink.background}`};
      & > p {
        font-size: 18px;
      }
    }
    & > .card-content-container {
      flex-basis: 60%;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};

      & > h3 {
        font-family: utopia-std-display;
        font-weight: normal;
        font-size: 36px;
        line-height: 50px;
      }
      & > .paragraph-container {
        padding: ${({ theme: { spacing } }: ThemeType) =>
          spacing(0, 4.5, 3, 4.5)};

        & > p {
          color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      width: 100vw;
      overflow-x: hidden;
      padding-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
    }
  }
`;

export const CovidTagContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.covid-tag-container {
    position: absolute;
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.primary.main};
    color: ${({ theme: { palette } }: ThemeType) => palette.p_white.full};
    border-radius: 50%;
    font-size: 18px;
    width: 115px;
    height: 115px;
    left: 0px;
    top: 0px;
    transform: rotate(-12deg);
    line-height: 112%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > p {
      font-weight: 600;
      letter-spacing: 0.01em;
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      width: 120px;
      height: 120px;
      & > p {
        font-size: 13px;
      }
    }
  }
`;

export const CoverListContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.cover-list-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(10)};

    & > .get-covered-button {
      width: 230px;
      height: 40px;
      text-transform: none;
      box-shadow: none;
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        display: none;
      }
    }
    & > h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 112%;
      font-family: "utopia-std-display";
    }
    & > .cover-list-accordion-owner {
      background-color: transparent;
      box-shadow: none;
      width: 100%;
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
      }

      & > .cover-list-accordion-icon {
        gap: 8px;
        & > .MuiAccordionSummary-expandIconWrapper {
          & > svg {
            transform: rotate(180deg);
          }
        }
        & > .MuiAccordionSummary-content {
          & > h3 {
            font-size: 16px;
            font-weight: 600;
          }
        }

        & > .MuiAccordionSummary-content {
          margin: 0;
          flex-grow: 0;
        }
        &.MuiAccordionSummary-root {
          min-height: 0;
        }
      }

      .cover-list-accordion {
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(6, 10)};
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(2.5, 2)};
        & > .cover-page-link {
          text-align: center;
          grid-column: 2/3;
          width: 100%;
          margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(7)};
          & > span {
            font-size: 14px;
            letter-spacing: 0.015em;
            border-bottom: 1px solid
              ${({ theme: { palette } }: ThemeType) => palette.primary.main};
            padding-bottom: ${({ theme: { spacing } }: ThemeType) =>
              spacing(0.2)};
          }
        }
        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          grid-template-columns: 1fr;
          grid-auto-rows: 1fr;
          gap: 0;
          padding: ${({ theme: { spacing } }: ThemeType) =>
            spacing(2, 0, 0, 0)};
          background-color: white;
          border-radius: 4px;
          margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(2.5)};

          &:last-of-type {
            background-color: transparent;
          }
          & > .cover-page-link {
            grid-column: auto;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: ${({ theme: { spacing } }: ThemeType) =>
              spacing(4, 0, 0, 0)};
          }
        }
      }
    }
  }
`;

export const SingleCoverContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.single-cover-container {
    border-radius: 4px;
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.p_white.full};
    height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(14px, 1.5vw, 18px);
    line-height: 134.24%;
    text-align: center;
    position: relative;
    padding-inline: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
    &.is-addon {
      border: 1px solid rgba(71, 38, 209, 0.5);
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      &.is-addon {
        border: none;
      }
      font-size: 16px;
      box-shadow: none;
      justify-content: space-between;
      text-align: start;
      height: 60px;
      font-weight: 400;
      padding-inline: ${({ theme: { spacing } }: ThemeType) => spacing(2)};

      border-bottom: ${({ theme: { palette } }: ThemeType) =>
        `1px solid ${palette.p_pink.background}`};
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const AddonTagContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.addon-tag-container {
    border-radius: 4px;
    padding: 4px 8px;
    text-align: center;
    position: absolute;
    top: -8px;
    margin: 0 auto;
    font-size: 12px;
    line-height: 112%;
    letter-spacing: 0.01em;
    &.your-health {
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.primaryLight};
    }
    &.your-stuff {
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.p_yellow.addon};
    }
    &.your-trip {
      background-color: ${({ theme: { palette } }: ThemeType) =>
        palette.p_pink.background};
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      display: none;
      top: auto;
      position: relative;
      margin: inherit;
    }
  }
`;
